import React from 'react';
import "./About.scss";
import Section from "../../components/Section/Section";
import banner from "../../img/about-img.png";

function About() {
	return (
		<Section>
			<div className="about">
				<h1>О компании</h1>
				<h3>
					<span>Здравствуйте</span>, мы являемся международной логистической компанией Китая «AIDADECK-Logistics».  Мы поможем вам найти подходящих поставщиков в Китае, проверить качество товаров, разместить заказы и отправить. Мы сможем перевезти грузы из Китая в Россию, Казахстан, Кыргызстан и Беларусь.  Мы спланируем наиболее подходящий для вас маршрут перевозки и предложим вам на выбор различные виды транспорта. Пожалуйста, не беспокойтесь о таможенных вопросах, потому что мы отвечаем за растаможку.  Если вы заинтересованы в наших услугах или у вас есть вопросы, свяжитесь с нами.<br></br>
					<span>В</span> компании созданы все условия, чтобы любой из сотрудников смог максимально полно реализовать свой потенциал. Помимо этого, непременным условием улучшения компании является развитие наших менеджеров. С этой целью регулярно проводятся технические семинары и тренинги, а также консультации в сфере продаж и обслуживания клиентов. Наша миссия - постоянно развиваться и повышать качество нашей услуги за счёт внедрения инновационных технологий. Мы ответственно и тщательно выполняем свою работу, а также всегда ориентируемся исключительно на интересы клиентов. У нас вас ждет целый комплекс мероприятий — от встречи с партнерами и ведения деловых переговоров до растаможки и доставки до вашей двери. Оказание услуг «под ключ» полностью избавит вас от всевозможных забот, рисков и необоснованных затрат. Наша команда состоит из компетентных, внимательных и опытных специалистов, которые знают правила и тонкости международных грузоперевозок. Грузы из Китая мы доставляем быстро и аккуратно, гарантируя клиентам полную сохранность заграничных отправлений.
				</h3>

				<h3>Мы оказываем логистические услуги:</h3>
				<ul>
					<li>физическим лицам</li>
					<li>юридическим лицам</li>
					<li>индивидуальным предпринимателям</li>
				</ul>
				<div className="image-about">
					<img src={banner} alt="img" loading={"lazy"}/>
				</div>
			</div>
		</Section>
	);
}

export default About;
